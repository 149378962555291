<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div v-html="lesson.content">
        </div>
      </div>
    </div>

    <div class="row mt-5">
      <div class="col-md-12">
        <div class="progress">
          <div class="progress-bar" role="progressbar" v-bind:style="getProgress()" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
      </div>
    </div>
    
  </div>
  
</template>

<script>
import axios from 'axios'
export default {
  name: 'Lesson',
  props: [
    'id'
  ],
  data: function () {
    return {
        course: {},
        lessons: [],
        lesson: {},
        show: true,
        isCollapsed: true,
        loremIpsum: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.'
    }
  },
  mounted() {
    const token = this.$cookies.get('login_token')
      axios
        .get(process.env.VUE_APP_BACKEND_URL+'/api/lesson/' + this.id, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        .then(response => {
          this.lesson = response.data.data
        })
  },
  methods: {
    getProgress() {
      console.log(this.lesson.course_user);
      let width = this.lesson.course_user.completed / this.lesson.course_user.total * 100;
      return {'width': width + '%'}
    }
  }
}
</script>
